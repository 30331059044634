import { render, staticRenderFns } from "./ra-aus-now-allowed-to-fly-in-tower-hours.md?vue&type=template&id=6a5f8c72&scoped=true&"
var script = {}
import style0 from "./ra-aus-now-allowed-to-fly-in-tower-hours.md?vue&type=style&index=0&id=6a5f8c72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5f8c72",
  null
  
)

export default component.exports